export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'birth_certificate', label: "Akte/Bukti Bayar", _style:'min-width:100px' },
    { key: 'fullname', label: "Nama Lengkap", _style:'min-width:100px' },
    { key: 'nickname', label: "Nama Panggilan", _style:'min-width:100px' },
    { key: 'comunity', label: "Komunitas", _style:'min-width:100px' },
    { key: 'team', label: "Team", _style:'min-width:100px' },
    { key: 'plat_number', label: "Nomor Plat", _style:'min-width:100px' },
    { key: 'birth_of_date', label: "Tanggal Lahir", _style:'min-width:100px' },
    { key: 'phone_number', label: "Phone Number", _style:'min-width:100px' },
    { key: 'email', label: "Email", _style:'min-width:100px' },
    { key: 'clothes_size', label: "Ukuran Baju", _style:'min-width:100px' },
    { key: 'category', label: "Kategori", _style:'min-width:100px' },
    { key: 'event_name', label: "Event", _style:'min-width:100px' },
    { key: 'status', label: "Status", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
